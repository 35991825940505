.marginBottom {
	margin-bottom: rem($baseLineHeight);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animatedTransform;
	background: $alert;
	border: none;
	color: $light;
	cursor: pointer;
	display: block;
	font-size: rem($baseFontSize);
	line-height: rem($baseLineHeight);
	padding: 1rem 0.5rem;
	text-align: center;
	text-decoration: none;
	font-family: $mainFont;
	margin: .5rem 0;

	&.button-confirm{
		margin-bottom: $baseLineHeight;
	}

	@include breakpoint(small) {
		display: inline-block;
		padding: 1rem 1.5rem;
		margin: 0;
	}

	&:hover,
	&:active,
	&:focus {
		background: lighten($dark, 15%);
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hideText {
	@include hideText();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.floatLeft {
	float: left;
}

img.floatLeft {
	margin-right: 1rem;
}

.floatRight {
	float: right;
}

img.floatRight {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.nonFluid {
	width: auto !important;
}

.alignLeft {
	text-align: left;
}

.alignRight {
	text-align: right;
}

.alignCenter {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}
.center{
	align-items: center;
}
.middle {
	@include breakpoint(full,max){

		align-items: center;
	}
}

.lila {
	background: $secondary;
}

.reverse {
	@include breakpoint(large) {

		flex-direction: row-reverse;
	}
}

.distanceBottom {
	margin-bottom: 2rem;
}

.count {
	@include breakpoint(medium) {
		column-count: 2;
	}
}

#content {
	.col { 

		@include breakpoint(large) {
			padding: 4rem 4rem calc(4rem - 30px) 4rem;
		}

		@include breakpoint(full) {
			padding: rem(120px);
		}

		// &:not(.lila) {
		// 	max-width:rem(1000px);
		// }

		&.col-text{
			@include breakpoint (full){
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
		}

		&.col-img {
			padding: 2rem $baseGap;
			margin-bottom: $baseLineHeight;

			@include breakpoint(medium) {
				padding: 2rem;
			}
			@include breakpoint(large) {
				margin-bottom: 0;
			}
			@include breakpoint(giant) {
				padding: 4rem;
			}
		
			@include breakpoint(full) {
				padding: rem(120px);
				display: flex;
				align-items: center;
			}
		}
	}
}

.footer-text{
	display: flex;
	justify-content: center;
	align-items: center;
	@include breakpoint(full,max){
		margin-bottom: rem($baseLineHeight);
	}
}

.footer-navigation-distance {
	padding: rem(60px) 0 rem(130px) 0;

	@include breakpoint(tiny){
		padding: rem(60px) 0;
	}

	@include breakpoint(medium) {
		padding: 2rem;
	}

	@include breakpoint(giant) {
		padding: 4rem;
	}

	@include breakpoint(full) {
		padding: rem(120px);
	}

}

.footer-distance {
	padding: rem(60px) 0;

	@include breakpoint (large){
		padding: 4rem;
	}

	@include breakpoint(full) {
		padding: rem(120px);
	}
}
.header-distance{
	@include breakpoint(medium) {
		padding: 2rem;
	}

	@include breakpoint(giant) {
		padding: 4rem;
	}

	@include breakpoint(full) {
		padding: rem(120px);
	}
}
.form-distance{
	padding: rem(60px) $baseGap rem(60px - 48px) $baseGap;

	&:nth-child(2){
		padding: 0 $baseGap rem(60px - $baseLineHeight) $baseGap;
	}

	@include breakpoint(large){
		padding: 0;
	}
}
.distance-privacy-policy{
	padding: rem(60px) 0 rem(60px - $baseLineHeight) 0;

	@include breakpoint(medium){
		padding: rem(60px) 0;
	}
	@include breakpoint(large){
		margin-bottom:(30px);
		padding: 0;
	}
	@include breakpoint(full){
		margin-bottom: 0;
	}
}
.distance {
	padding: rem(60px) 0 rem(60px - $baseLineHeight) 0;

	@include breakpoint(large){
		padding: 0;
	}

	&.section-index{
		@include breakpoint(large,max){
			flex-direction: column-reverse;
			align-items: flex-start;
		}
	}
}

.medium {
	background: $medium;
}

/* 16:9 video resolutions */
.videoContainer {

	&:not(.videoTag) {
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
		}

	}


	video {
		width: 100%;
		height: auto;
	}
}

body {
	&:before {
		$content: "default:"+$bpContext;
		width: str_length($content) * 6pt + $baseGap * 2;

		@each $point,
		$width in $gridMap {
			$content: $content +"...."+$point +":"+nth(map-get(map-get($gridMap, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $baseGap * 2;
			}
		}

		content: "#{$content}";
		display: none !important;
		/* Prevent from displaying. */
	}

	&.devmode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($baseGap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}

	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animatedTransform {
	transition: 0.3s;
}


.notificationArea {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 1000;

	> :not(:last-child) {
		margin-bottom: $baseGap;
	}

	.notification {
		position: relative;
		transition: 300ms all ease-in-out;
		overflow: hidden;
		padding: 1em;
		background: $alert;
		font-size: 12px;
		line-height: 16px;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
		width: 290px;
		max-width: calc(100vw - 40px);
		transform-origin: left center;

		&,
		a {
			color: $light;
		}

		.close {
			position: absolute;
			top: 5px;
			right: 8px;
			cursor: pointer;
			color: #fff;
			font-size: 1.2rem;
		}

		&.hide {
			opacity: 0;
			visibility: hidden;
			transform: scale(0);
			max-height: 0;
			height: 0;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}