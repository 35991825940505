* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {}

::-moz-selection {}

// DO NOT set font-size and line-height here!
// Adjust $baseFontSize and $baseLineHeight in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $mainFont;
	font-size: 100% * ($baseFontSize / 16px);
	hyphens: manual;
	font-weight: 300;
	line-height: rem($baseLineHeight);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($baseLineHeight);
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;

	&[data-src] {
		@extend %lazyloading;
	}
}


/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	@extend .marginBottom;
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	font-size: rem(25px);
	line-height: rem(32px);
	font-weight: normal;

	span {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $displayFont;
		font-size: rem(32px);
		line-height: rem(40px);
	}

	@include breakpoint(medium) {
		font-size: rem(30px);
		line-height: rem(37px);

		span {
			font-size: rem(50px);
			line-height: rem(64px);
		}
	}
}

h2,
.h2 {
	font-size: rem($h2Size);
	line-height: 1.3em;
	font-weight: normal;

	span {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $displayFont;
	}
}

h3,
.h3 {
	font-size: rem($h3Size);
	font-weight: normal;

	span {
		display: block;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $displayFont;
	}
}

h4,
.h4 {
	font-size: rem($h4Size);
	text-transform: uppercase;
	font-weight: bold;
	font-family: $displayFont;
}

h5,
.h5 {
	font-size: rem($h5Size);
	text-transform: uppercase;
	font-weight: bold;
	font-family: $displayFont;
}

h6,
.h6 {
	font-size: rem($h6Size);
	text-transform: uppercase;
	font-weight: bold;
	font-family: $displayFont;
}

/**
 * Links
 */
a {
	color: $alert;
	text-decoration: none;
	word-wrap: break-word;
	transition: all .3s ease-in-out;

	&:focus,
	&:hover,
	&:active {
		color: $secondary;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: underline;
	}

	&.footer-review-imh-link {

		&:hover,
		&:focus {
			opacity: 0.7;
		}
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styledList {

		li {
			padding-left: rem(15px);
			margin-bottom: rem(7px);

			&:before {
				@extend .icon;
				@extend .icon-angle-right;
				text-indent: rem(-15px);
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}

dl {
	&.styledList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: $medium;
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: 700;
		}

		dd {
			padding-top: 0;

			&:not(:last-child) {
				margin-bottom: rem(10px);
			}
		}
	}

	&.floatList {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
}

ol {
	list-style-type: none;
	counter-reset: item;

	li {
		padding-top: 0.3rem;
		padding-bottom: 0.3rem;
		display: table;
		counter-increment: item;

		&:before {
			content: counters(item, ".") ". ";
			display: table-cell;
			padding-right: 0.3em;
		}
	}

	ol {
		margin-bottom: 0;

		li {
			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	&[src^='http://cms.'],
	&[src^='https://cms.'] {
		max-width: none;
		width: auto;
	}

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazyloading;
	}
}

/**
 * Tables
 */

table {
	display: table;
	border-collapse: collapse;
	width: 100%;
	max-width: 100%;
	text-align: left;
	font-size: rem($baseFontSize);

	@include breakpoint(small, max) {
		overflow-x: auto;
		display: block;
	}

	caption {
		text-align: left;
		padding: rem($baseGap) rem($baseGap) rem(25px);
		display: table-caption;
		font-weight: 700;
		font-size: 1.2rem;
	}

	thead {
		border-collapse: collapse;
		display: table-header-group;
	}

	tbody {
		width: 100%;
		overflow-x: scroll;
		display: table-row-group;

		tr {
			padding: rem($baseGap) 0;

			&:not(:last-child) {
				border-bottom: rem(1px) solid $medium;
			}
		}
	}

	tr {
		display: table-row;
	}

	th,
	td {
		padding: rem($baseGap);
		display: table-cell;
		vertical-align: top;
	}

	@include breakpoint(small, max) {
		td {
			min-width: 50vw;
		}
	}
}