// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

$desktopHover: false; // Set Hover to reach Submenus for Desktop (true, false)

// NAVIGATION
//////////////////////////////////////////////

$navBg: $light; // Navigation Background
$navColor: $dark; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;

	@include breakpoint(giant) {
		padding: $baseGap .5rem;
	}

	@include breakpoint(huge) {
		padding: $baseGap;
	}
}

%buttonHover {
	// Link Hover
	color: $light;
	background-color: $primary;

	@include breakpoint($break) {
		background: transparent;
		color: $alert;
	}
}

%buttonActive {
	// Link Active
	color: $light;
	background-color: $dark;

	@include breakpoint($break) {
		background: transparent;
		font-weight: bold;
		color: $alert;
	}
}

%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotateY(180deg);

	@include breakpoint($break) {
		transform: rotate(90deg);
	}
}

%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);

	@include breakpoint($break) {
		transform: rotate(90deg);
	}
}

$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	nav {
		@include ul {
			&.navi {
				li {
					@include navButtons {
						@include navButton;
					}

					@include hasSub {
						@include sub {
							background: $navBg;

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
							}
						}
					}

					@include navBack {}
				}
			}
		}
	}

	.phone {
		padding: $baseGap;
		background: $secondary;
		color: $light;
		font-weight: bold;
		white-space: nowrap;

		&:before {
			padding-right: .5rem;
			font-family: $iconFont;
			font-weight: normal;
			content: map-get($iconMap, phone);
		}
	}
}

.socials {
	li {
		display: inline-block;
		padding: $baseGap;

		a {
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				opacity: .7;
			}

			&:after{
				content: '';
				width: rem(29px);
				height: rem(29px);
			}

			&.facebook {
				&:after {
					background: url('../images/layout/fbook-color.svg') no-repeat center center/contain;
				}
			}

			&.instagram {
				&:after {
					background: url('../images/layout/instagram-custom.svg') no-repeat center center/contain;
				}
			}

			&.yelp {
				&:after {
					background: url('../images/layout/yelp-color.svg') no-repeat center center/contain;
					width: rem(32px);
					height: rem(32px);
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	.flexBox {
		margin-left: auto;
		margin-right: auto;
		max-width: rem($rowMaxWidth);
		width: 100%;
		position: relative;
		display: flex;
		flex: 0 1 auto;
		flex-direction: row;
		justify-content: space-between;
		align-items: stretch;

		.right {
			display: flex;
			align-items: center;

			ul {
				margin-right: 1.5rem;
			}
		}
	}

	#navigation {
		nav {

			ul {
				&.navi {
					li {
						display: inline-block;

						// padding:0 .5rem;
						&.hasSub {
							.sub {
							}
						}

						&.nav-back {
							transition: 0.5s ease-in-out;

							&:hover,
							&:focus {
								background: $secondary;

								&:before {
									color: $light;
								}
							}
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	@extend %animatedTransform;
	background-color: $medium;
	color: $primary;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap) 0;
	z-index: 1000;

	a{
		&:after{
			display: none;
		}
	}

	img{
		width: rem(29px);
		height: rem(29px);
	}

	.phone {
		text-indent: -9999px;
		width: rem(50px);
		height: rem(50px);
		background: $alert;
		position: relative;

		&:before {
			text-indent: 0;
			color: $light;
			font-family: $iconFont;
			content: map-get($iconMap, phone);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}