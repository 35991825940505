
// HEADER
//////////////////////////////
#pageWrap{
	overflow:hidden;
}
#header {
	position: relative;
	padding: rem(100px) 0 calc(60px - 9px);
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	@include responsiveImage('layout/bgHeader.jpg', (tiny, medium), false);

	@include breakpoint(medium) {
		padding: 4rem 0 0rem;
		body.index &{
			padding: 10rem 0 8rem;
		}
	}

	.branding {
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		margin-bottom:1.5rem;
	}

	blockquote{
		color:$light;
		font-family:$mainFont;
		font-weight:normal;
		margin-bottom:1.5rem;
		@include breakpoint(large){
			font-size:rem(25px);
			line-height:rem(35px);
		}
		span{
			display:block;
			font-family:$displayFont;
			text-transform: uppercase;
			font-size:rem(32px);
			line-height:rem(40px);
			font-weight:bold;
			@include breakpoint(large){
				font-size:rem(65px);
				line-height:rem(79px);
			}
		}
	}

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}
}

// MAIN CONTENT
//////////////////////////////

blockquote{
	font-family:$displayFont;
	text-transform: uppercase;
	font-weight:bold;
	font-size:rem(32px);
	line-height:rem(40px);
	// padding:2rem 0;

	@include breakpoint(large){
		font-size:rem(90px);
		line-height:rem(110px);
	}
}
main {
	display: block;
	.images{
		@include breakpoint(small){
		column-count:2;
		}
			img{
				float:left;
				margin:$baseGap 0;
			}
		body:not(.cmsbackend) &{
			position:relative;
			transition: all .3s ease-in-out;
			.before{
				position:absolute;
				top:50%;
				width:auto;
				left:50%;
				z-index:10;
				transform:translate(-50%, -50%);
				transition: all .3s ease-in-out;
			}
		}
	}

	.photo{
		@include breakpoint(full){
			.row{
				&:before{
					content:'';
					width:rem(542px);
					height:rem(158px);
					background:url('../images/layout/photo.jpg') no-repeat center center;
					position:absolute;
					bottom:0;
					left:0;
				}
			}
		}
	}
	.film{
		@include breakpoint(full){
			.row{
				&:before{
					content:'';
					width:rem(276px);
					height:rem(250px);
					background:url('../images/layout/film.jpg') no-repeat center center;
					position:absolute;
					bottom:0;
					right:0;
				}
			}
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList) {
		@extend .styledList;
	}

	.googleMaps {
		@extend .videoContainer;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	.teaser{
		display:flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap:wrap;
		width:100%;

		li{
			padding:$baseGap;
			width:100%;
			transition: all .5s ease-in-out;
			// height:100%;
			a{
				background:$light;
				text-align: center;
				padding:rem(60px) 2rem rem(80px);
				display:block;
				position:relative;
				color:$primary;
				height:100%;
				text-transform: uppercase;
				transition: all .5s ease-in-out;
				svg{
					margin-bottom:1.5rem;
					height:rem(200px);
					transition: all .5s ease-in-out;
					path, rect{
						transition: all .3s ease-in-out;
					}
				}
				span{
					padding-top:1.5rem;
					border-top:$baseBorder;
					display:block;
					text-align: left;
					transition: all .5s ease-in-out;
				}
				&:before{
					content:'+';
					background:$alert;
					color:$light;
					width:rem(50px);
					height:rem(50px);
					position:absolute;
					bottom:0;
					right:0;
					display:flex;
					justify-content:center;
					align-items: center;
					transition: all .5s ease-in-out;
					white-space: nowrap;
				}
				&:hover{
					svg{
						path, rect{
							fill:$alert;
						}
					}
					&:before{
						content:'Read More +';
						width:100%;
						transition: all .5s ease-in-out;
					}
				}
			}
			@include breakpoint(small){
				width:50%;
				body:not(.internetExplorer ){
					flex:1 1 50%;
				}
			}
			@include breakpoint(full){
				width:25%;
				body:not(.internetExplorer ){
					flex:1 1 25%;
				}
			}
		}
	}
}

// Define some variables for collapser usage
$collapser-class: ".collapser";
$collapser-gaps-lr: 1.2rem;
$collapser-gaps-tb: 1rem;
$collapser-color: $secondary; 
$collapser-dark: $light; 

// Dont Edit Inside the mixin, overwrite styles in @include collapser
@mixin collapser {

    #{$collapser-class} {
        background: $collapser-color;
        padding: $collapser-gaps-tb $collapser-gaps-lr;
        position: relative;
        cursor: pointer;
        color:$light;

        &:not(.collapser-initialized) {
            background: none;
            padding:0;

            &:after, &:before {
                display:none;
            }
        }

        &:after, &:before {
            background: $collapser-dark;
            width:25px;
            height: 3px;
            content:"";
            position: absolute;
            right: $collapser-gaps-lr;
            transition: all 300ms;
        }
        &:after {
            top: 50%;
        }
        &:before {
            transform: rotate(90deg);
            top:50%;
        }

        &.active {
            &:before {
                transform: rotate(-135deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }

        & + * {
            overflow:hidden;
            transition: max-height 300ms;

            &.limited {
                overflow-y:scroll;
                margin-bottom: 1rem;
            }
        }

        @content;
    }
}

// Add your custom shine here ;)
@include collapser {

}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {
	text-align: center;
	iframe{
		min-height:rem(400px);
	}

	.banner{
		display:flex;
		align-items:center;
		justify-content:center;
		p{
			padding-left:1rem;
		}
		@include breakpoint(large){
			justify-content:flex-start;
		}
	}
	.navi {
		text-align: center;
	}

	@include breakpoint(large){
		text-align: left;
	}
}







#header{
	.animate{
		 animation: moveUp 1s both 1s linear;
        -webkit-animation: moveUp 1s both 1s linear;
        -o-animation: moveUp 1s both 1s linear;
        -ms-animation: moveUp 1s both 1s linear;
        -moz-animation: moveUp 1s both 1s linear;
	}
}

body.javascript{
	.lila{
		&.animate{
			position:relative;
			background:none;
			&:before{
				content:'';
				width:0;
				height:100%;
				background:$secondary;
				position:absolute;
				top:0;
				left:0;
				z-index:-1;
			}
			img{
				opacity:0;
			}
			&.show{
				&:before{
					width:0;
					height:100%;
					animation: widthReplace .5s both .5s linear;
			        -webkit-animation: widthReplace .5s both .5s linear;
			        -o-animation: widthReplace .5s both .5s linear;
			        -ms-animation: widthReplace .5s both .5s linear;
			        -moz-animation: widthReplace .5s both .5s linear;
				}
		        img{
		        	animation: moveUp .5s both .5s linear;
			        -webkit-animation: moveUp .5s both .5s linear;
			        -o-animation: moveUp .5s both .5s linear;
			        -ms-animation: moveUp .5s both .5s linear;
			        -moz-animation: moveUp .5s both .5s linear;
		        }
			}
		}
	}
	.animate-navigation{
			@include breakpoint(giant){
				&.navigation-shadow{
		
					box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
				}
			}
	}
}





    /* moveUp */
    
@-webkit-keyframes moveUp {
 0% {
 transform:translateY(50px);
 opacity:0;
}
 100% {
 transform:translateY(0px);
 opacity:1;
}

}
 @-moz-keyframes moveUp {
 0% {
 transform:translateY(50px);
 opacity:0;
}
 100% {
 transform:translateY(0px);
 opacity:1;
}
}
 @-o-keyframes moveUp {
 0% {
 transform:translateY(50px);
 opacity:0;
}
 100% {
 transform:translateY(0px);
 opacity:1;
}
}
 @-ms-keyframes moveUp {
 0% {
 transform:translateY(50px);
 opacity:0;
}
 100% {
 transform:translateY(0px);
 opacity:1;
}
}
 @keyframes moveUp {
 0% {
 transform:translateY(50px);
 opacity:0;
}
 100% {
 transform:translateY(0px);
  opacity:1;
}
}

    /* widthReplace */
    
@-webkit-keyframes widthReplace {
 0% {
 	width:0;
}
 100% {
  width:100%;
}

}
 @-moz-keyframes widthReplace {
 0% {
 	width:0;
}
 100% {
  width:100%;
}
}
 @-o-keyframes widthReplace {
 0% {
 	width:0;
}
 100% {
  width:100%;
}
}
 @-ms-keyframes widthReplace {
 0% {
 	width:0;
}
 100% {
  width:100%;
}
}
 @keyframes widthReplace {
 0% {
 	width:0;
}
 100% {
  width:100%;
}
}